<template>
    <div>
        <div class="contentWidth" style="background-color:#ffffff; margin: 16px auto;padding-top: 20px;">
            <div class="header-img1" style="">
                <img style="border-radius: 0px;" src="../../../assets/images/Hkd.png" alt="" />
            </div>

            <div style="width:100%;margin-top: 0px;">
                <div style="font-size: 24px;">HKD 轉數快</div>
            </div>
            <div style="width:100%;margin-top: 0px;">
                <div style="font-size: 16px; color: #8d8d8d;">{{ payOrderInfo.mchName }}</div>
                <div style="font-size: 16px; color: #8d8d8d;margin-top: 10px;">請您在規定時間內將款項匯入以下帳戶
                </div>
            </div>

            <!-- 读取数据时动画div需要显示，可控制display的none与block来做显示还是隐藏 -->
            <div style="display: none ;">
                <div class="loader" style="margin: 50px auto 20px auto;"></div>
                <div style="font-size:18px; height: 60px;">00:00:00</div>
            </div>

            <!-- 读取数据时整个信息div需要不显示，可控制display的none与block来做显示还是隐藏 -->
            <div style="display: block ;">
                <!-- <img :src="qrcode" style=" width: 152px; margin-top: 8px;" alt="" /> -->
                <div v-if="payOrderInfo.amount != payOrderInfo.amountActual"
                    style="width: 100%; display: flex; margin-top: 10px;">
                    <div class="marginLeft-title"
                        style="font-size: 18px; color: #8d8d8d; width: 28%; text-align: left;height: 26px; line-height: 26px; ">
                        金額</div>
                    <div
                        style="font-size: 26px; height: 26px;line-height: 26px; font-weight: 600;color: #8d8d8d; text-decoration: line-through;text-decoration-style: solid; ">
                        {{ payOrderInfo.amount }}</div>
                </div>

                <div v-if="payOrderInfo.amount != payOrderInfo.amountActual"
                    style="width: 100%; display: flex; margin-top: 10px; margin-bottom: 10px;">
                    <div class="marginLeft-title"
                        style="font-size: 18px; color: #8d8d8d; width: 28%; text-align: left;height: 26px; line-height: 26px; ">
                        帳戶名稱</div>
                    <div style="font-size: 26px; height: 26px;line-height: 26px; font-weight: 600; ">{{
                        payOrderInfo.amountActual }}</div>
                    <div style="font-size: 18px; height: 24px;line-height: 24px; color: #ff1b4b; margin-left: 10px; ">
                        ({{
                            payOrderInfo.amountActual - payOrderInfo.amount }})</div>
                </div>

                <div v-if="payOrderInfo.amount == payOrderInfo.amountActual"
                    style="width: 100%; display: flex; margin-top: 10px;">
                    <div class="marginLeft-title"
                        style="font-size: 18px; color: #8d8d8d; width: 28%; text-align: left;height: 26px; line-height: 26px; ">
                        金額</div>
                    <div style="font-size: 26px; height: 26px;line-height: 26px; font-weight: 600; ">{{
                        payOrderInfo.amount }}</div>
                </div>

                <div class="marginWidthPc"
                    style="background-color: #EDEDED; border-radius: 8px;margin: 10px auto; padding-top: 20px; padding-bottom: 20px;">
                    <div style="width: 100%; display: flex; ">
                        <div
                            style="font-size: 18px; color: #8d8d8d; width: 30%; margin-left: 14px; text-align: left;height: 26px;line-height: 26px;">
                            帳戶名稱</div>
                        <div style="font-size: 18px; margin-right: 10px;height: 26px;line-height: 26px;">{{
                            payApiRes.truename }}
                        </div>
                        <button size="small"
                            style="background-color: #14C2A3;  border: none; border-radius: 30px; color: #ffffff; font-size: 14px; min-width:50px;"
                            v-clipboard:copy="payApiRes.truename" v-clipboard:success="onCopy">複製</button>
                    </div>

                    <div style="width: 100%; display: flex; margin-top: 10px;">
                        <div
                            style="font-size: 18px; color: #8d8d8d; width: 28%; margin-left: 14px; text-align: left;height: 26px;line-height: 26px;">
                            帳號</div>
                        <div style="font-size: 18px; margin-right: 10px;height: 26px;line-height: 26px;">{{
                            payApiRes.account }}</div>
                        <button size="small"
                            style="background-color: #14C2A3;  border: none; border-radius: 30px; color: #ffffff; font-size: 14px; min-width:50px;"
                            v-clipboard:copy="payApiRes.account" v-clipboard:success="onCopy">複製</button>
                    </div>

                    <div style="width: 100%; display: flex; margin-top: 20px;">
                        <div
                            style="font-size: 18px; color: #8d8d8d; width: 28%; margin-left: 14px; text-align: left; height: 26px; line-height: 26px;">
                            {{ payApiRes.type === 1 ? '預設銀行' : payApiRes.type === 2 ? '銀行code' : '銀行code' }}
                        </div>
                        <div style="font-size: 18px; margin-right: 10px; height: 26px; line-height: 26px;">{{
                            payApiRes.ifsc }}
                        </div>
                        <button size="small"
                            style="background-color: #14C2A3; border: none; border-radius: 30px; color: #ffffff; font-size: 14px; min-width:50px;"
                            v-clipboard:copy="payApiRes.ifsc" v-clipboard:success="onCopy">複製</button>
                    </div>
                </div>

                <div style="width: 100%; display: flex; margin-top: 10px;">
                    <div class="marginLeft-title"
                        style="font-size: 18px; color: #8d8d8d; width: 28%; text-align: left;height: 26px; line-height: 26px; ">
                        可用時間</div>
                    <div style="font-size: 18px; height: 26px;line-height: 26px; ">{{ orderTime }}</div>
                </div>

                <div style="width: 100%; display: flex; margin-top: 10px;">
                    <div class="marginLeft-title"
                        style="font-size: 18px; color: #8d8d8d; width: 28%; text-align: left;height: 26px; line-height: 26px; ">
                        訂單號
                    </div>
                    <div style="font-size: 18px; height: 26px;line-height: 26px; ">{{ payOrderInfo.mchOrderNo }}</div>
                </div>
                <div class="marginBottom" style="font-size: 20px; color: #ffffff;">.</div>
            </div>

        </div>
    </div>
</template>

<script>
import { getPayPackage, getPayOrderInfo, getPayOrderState } from '@/api/api'
import config from "@/config";
import QRCode from 'qrcode';
import CountDownTime from "@/utils/countDownTime";
export default {

    data: function () {
        return {
            merchantName: 'BeingFi',  // 付款的商户默认
            avatar: require("../../../assets/images/zfb.jpeg"), // 商户头像默认
            amount: 1,  // 支付金额默认
            resData: {},
            wxImg: require("../../../assets/images/zfb.jpeg"), // 微信支付图片
            payOrderInfo: {}, //订单信息
            payApiRes: {}, //支付api返回信息
            qrcode: "", //二维码
            intervalOrderState: null, //订单状态定时器
            orderTime: "00:00:00" //剩余时间
        }
    },

    mounted() {
        this.setPayOrderInfo(true); //获取订单信息 & 调起支付插件
    },

    methods: {

        setPayOrderInfo(isAutoPay) {
            const that = this
            getPayOrderInfo().then(res => {
                that.payOrderInfo = res
                that.checkOrderState()
                CountDownTime.initCountdown(that.payOrderInfo.ext.ExpiredTimeStamp, (time) => {
                    that.orderTime = time
                })
                if (isAutoPay) {
                    that.pay()
                }
            })
                .catch(res => {
                    that.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.msg } })
                });
        },

        pay: function () {

            let that = this;
            getPayPackage(this.amount).then(res => {

                //订单创建异常
                if (res.code != '0') {
                    return alert(res.msg);
                }

                if (res.data.orderState != 1) { //订单不是支付中，说明订单异常
                    return alert(res.data.errMsg);
                }

                if (res.data.payDataType == "jsonData") {
                    that.payApiRes = JSON.parse(res.data.payData);
                    // that.setQRCode(that.payApiRes.codeUrl)
                }
            }).catch(res => {
                that.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.msg } })
            });
        },
        onCopy() {
            alert('複製成功'); //复制成功
        },
        // 根据字符串生成二维码
        setQRCode(str) {
            QRCode.toDataURL(str, { width: 192, margin: 1 }).then(res => {
                // toDataURL方法返回一个promise
                this.qrcode = res;
            })
        },
        // 检查订单状态
        checkOrderState() {
            let that = this;
            //定时器
            that.intervalOrderState = setInterval(function () {
                getPayOrderState().then(res => {
                    if (res === 2) { //订单成功状态
                        clearInterval(that.intervalOrderState);
                        location.href = that.payApiRes.returnUrl;
                    }
                    if (res === 3) { //订单失败状态
                        clearInterval(that.intervalOrderState);
                        that.$router.push({ name: config.errorPageRouteName, params: { errInfo: "Order Failure !!!" } })
                    }
                    if (res === 6) { //订单过期状态
                        clearInterval(that.intervalOrderState);
                        that.$router.push({ name: config.errorPageRouteName, params: { errInfo: "Order Expired !!!" } })
                    }
                }).catch(res => {
                    clearInterval(that.intervalOrderState);
                    that.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.msg } })
                })
            }, 5000); //5秒检查一次
        }
    }
}
</script>
<style lang="css" scoped>
@import './../pay.css';
</style>